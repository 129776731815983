<template>
	<!-- https://pokemon.fandom.com/wiki/List_of_Generation_I_Pokémon -->
	<h1>Pokémon Type Favourite Game</h1>
	<router-view />
</template>

<style lang="scss">
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #000;
}
</style>
